import * as turf from '@turf/turf';
import { LatLng, LatLngBounds, latLngBounds, LatLngBoundsExpression, LatLngTuple } from 'leaflet';
import { MAP_DEFAULT_POSITION, MAP_MIN_DISTANCE } from '../constants';
import { GeometryType } from '../enums';
import { Position } from 'geojson';
import { IPublicDomainGeometry, IPublicDomainGeometryCoord, IPublicDomainIntake, IGeometry } from '../../types';

/**
 * Reverse the intake geometry coordinates, depending on the type
 *
 * @param {IPublicDomainIntake} intake
 */
export function intakeReverseCoordinates(intake?: IPublicDomainIntake): IPublicDomainGeometry | undefined {
  let coordinates: any;

  if (intake?.geometry && intakeHasCoordinates(intake)) {
    switch (intake.geometry.type) {
      case GeometryType.Point:
        coordinates = [...intake.geometry.coordinates!].reverse();
        break;
      case GeometryType.LineString:
        coordinates = intake.geometry.coordinates!.map((x) => [...x].reverse());
        break;
      case GeometryType.Polygon:
        coordinates = intake.geometry.coordinates![0].map((x: any) => [...x].reverse());
        break;
    }
    const geometry = {
      ...intake.geometry,
      coordinates,
    };
    return geometry as IPublicDomainGeometry;
  }
  return undefined;
}

export const reversePointCoordinates = (coords: Position): Position => [...coords].reverse();
export const reverseLineCoordinates = (coords: Position[]): Position[] => coords.map(reversePointCoordinates);
export const reversePolygonCoordinates = (coords: Position[][]): Position[][] => coords.map(reverseLineCoordinates);

export const getLatLng1D = (coords: Position): LatLng =>
  // This is just an easy determination for city of Antwerp as lat will always be around 51 and lng around 4
  new LatLng(Math.max(...coords), Math.min(...coords));
export const getLatLng2D = (coords: Position[] = []): LatLng[] => coords.map(getLatLng1D);
export const getLatLng3D = (coords: Position[][] = [[]]): LatLng[][] => coords.map(getLatLng2D);
export const getLatLng4D = (coords: Position[][][] = [[[]]]): LatLng[][][] => coords.map(getLatLng3D);

export const getLatLngTuple1D = (coords: LatLng): LatLngTuple => [coords.lat, coords.lng];
export const getLatLngTuple2D = (coords: LatLng[]): LatLngTuple[] => coords.map(getLatLngTuple1D);
export const getLatLngTuple3D = (coords: LatLng[][]): LatLngTuple[][] => coords.map(getLatLngTuple2D);

export const getLatLngTuple1DReversed = (coords: LatLng): LatLngTuple => [coords.lng, coords.lat];
export const getLatLngTuple2DReversed = (coords: LatLng[]): LatLngTuple[] => coords.map(getLatLngTuple1DReversed);
export const getLatLngTuple3DReversed = (coords: LatLng[][]): LatLngTuple[][] => coords.map(getLatLngTuple2DReversed);

export const getLatLngBounds = (geometry: IGeometry): LatLngBounds => {
  switch (geometry.type) {
    case 'Point':
      return latLngBounds([getLatLng1D(geometry.coordinates)]);
    case 'MultiPoint':
    case 'LineString':
      return latLngBounds(getLatLng2D(geometry.coordinates));
    case 'MultiLineString':
    case 'Polygon':
      return latLngBounds(getLatLng3D(geometry.coordinates).flat());
    case 'MultiPolygon':
      return latLngBounds(getLatLng4D(geometry.coordinates).flat(2));
  }
};

/**
 * Check if an intake has an intake
 *
 * @param {IPublicDomainIntake} intake
 */
export const intakeHasCoordinates = (intake?: IPublicDomainIntake) => !!intake?.geometry?.coordinates?.length;

/**
 * Reduce the geometry to an intake to a central position
 *
 * @param {IPublicDomainIntake} intake
 */
export function mapPosition(geometry?: IPublicDomainGeometry): IPublicDomainGeometryCoord {
  if (geometry?.coordinates) {
    switch (geometry.type) {
      case GeometryType.Point:
        return geometry.coordinates as any;
      default:
        return [
          geometry.coordinates.map((x) => x[0]).reduce((p, c) => p + c, 0) / geometry.coordinates.length,
          geometry.coordinates.map((x) => x[1]).reduce((p, c) => p + c, 0) / geometry.coordinates.length,
        ];
    }
  }
  return MAP_DEFAULT_POSITION;
}

/**
 *  Calls a callback function when points are closer than the specified distance together
 *
 * @param coordinates: array of coordinates to be validated.
 * @param distance: maximum distance in meters
 */
export const geometryHasPointsCloseTogether = (
  coordinates: IPublicDomainGeometryCoord[],
  distance: number = MAP_MIN_DISTANCE,
) => {
  const points = coordinates
    .slice(0, coordinates.length - 1)
    .map((coordinate) => new LatLng(coordinate[0], coordinate[1]));

  return points.some((point, index) =>
    points.filter((v, i) => i > index).some((otherPoint) => otherPoint.distanceTo(point) < distance),
  );
};

export const geometryHasIntersections = (coordinates: IPublicDomainGeometryCoord[]) => {
  const poly = turf.polygon([coordinates]);
  const kinks = turf.kinks(poly);
  return kinks.features.length > 0;
};

export const boundsAreLatLngBoundsAndAreEqual = (
  expression1: LatLngBoundsExpression,
  expression2?: LatLngBoundsExpression,
) => {
  return (
    expression1 instanceof LatLngBounds &&
    expression2 instanceof LatLngBounds &&
    (expression1 as LatLngBounds).equals(expression2 as LatLngBounds)
  );
};

export const ensureBelgianCoordinateFormat = (coordinates: LatLngTuple) => coordinates.sort((a, b) => b - a);
