import IState from '../../../state';
import { createSelector } from '@reduxjs/toolkit';
import { selectSettings } from '../metadata.selectors';
import { getRequest, selectRequestAttachments } from './request.selectors';
import { APP_DEFAULT_DATE_FORMAT, PHASE_CREATE } from '../../../common/constants';
import { GeoDrawingType, ISgwPhase } from '../../../types';
import { mapIdsToEntities } from '../../../utils';
import { earliestDate, formatDate, latestDateComparedToToday, sortByDate } from '../../../common/utils/dateUtils';
import moment from 'moment';
import { translate } from '../../../translations/translate';
import { createOption } from '../../../common/utils/formUtils';

export const selectPhasesById = (state: IState) => state.sgw?.phase?.list?.byId || {};
export const selectPhasesAllIds = (state: IState) => state.sgw?.phase?.list?.allIds || [];
export const selectPhaseFormFieldsChanges = (state: IState) => state.sgw?.phase?.formFieldsChanged;

export const getPhase = (phaseId: string, copyId?: string) =>
  createSelector([selectPhasesById], (phases) => {
    const phaseToCopy = phases[copyId || ''];
    const { id, ...copiedFieldsWithoutId } = phaseToCopy || {};
    const copiedFields = !!phaseToCopy ? copiedFieldsWithoutId : undefined;

    const defaultInitialPhase: Partial<ISgwPhase> = {
      timeFrom: '07:00',
      timeUntil: '17:00',
      workingHoursFrom: '07:00',
      workingHoursUntil: '17:00',
    };

    return phaseId !== PHASE_CREATE ? phases[phaseId] : copiedFields || defaultInitialPhase;
  });

export const getCompletePhase = (phaseId: string) => createSelector([selectPhasesById], (phases) => phases[phaseId]);

export const getPhases = (requestId: string) =>
  createSelector([getRequest(requestId), selectPhasesById], (request, byId) => {
    const ids = request?.phases || [];
    const allPhases = mapIdsToEntities(ids, byId);
    return allPhases.filter((phase, index) => phase?.id && allPhases.indexOf(phase) === index);
  });

export const getStandardPhases = (requestId: string) =>
  createSelector([getPhases(requestId)], (phases) =>
    phases.filter((phase) => !phase.isRecurringPhaseTemplate && !phase.parentPhaseId),
  );
export const getRecurringPhaseTemplates = (requestId: string) =>
  createSelector([getPhases(requestId)], (phases) => phases.filter((phase) => phase.isRecurringPhaseTemplate));

export const getStandardAndRecurringPhases = (requestId: string) =>
  createSelector([getPhases(requestId), getStandardPhases(requestId)], (phases, standardPhases) =>
    standardPhases.flatMap((phase) => [phase, ...phases.filter((p) => p.parentPhaseId === phase.id)]),
  );

export const getPhaseOptions = (requestId: string) =>
  createSelector([getStandardPhases(requestId), getRecurringPhaseTemplates(requestId)], (phases, recurringPhases) => [
    ...phases.map(({ id, phaseName }, index) =>
      createOption(phaseName || translate('sgw.overview.phases.subTitle', { index: index + 1 }), `${id}`),
    ),
    ...recurringPhases.map(({ id, phaseName }, index) =>
      createOption(phaseName || translate('sgw.phases.overview.recurringPhaseIndex', { index: index + 1 }), `${id}`),
    ),
  ]);

export const getPhaseOverviewInfo = (requestId: string) =>
  createSelector([getPhases(requestId), selectPhasesById, selectRequestAttachments], (allPhases, byId, attachments) =>
    attachments.map(({ sgwPhases, fileStorage: { name, url }, category }) => {
      const ids = sgwPhases.map((phase) => `${phase}`);
      const phases = mapIdsToEntities(ids, byId).sort(({ dateFrom: a }, { dateFrom: b }) => sortByDate(a, b));
      return {
        name,
        url,
        category,
        phaseNames: phases?.map((original) => {
          const indexOfPhase = allPhases.map((phase) => phase?.id).indexOf(original?.id);
          return original?.phaseName || `Fase ${indexOfPhase + 1}`;
        }),
      };
    }),
  );

export const getPhaseIds = (requestId: string) =>
  createSelector([getRequest(requestId)], (request) => request?.phases || []);

export const getStandardPhaseIds = (requestId: string) =>
  createSelector([getPhases(requestId)], (phases) =>
    phases.filter((phase) => !phase.isRecurringPhaseTemplate && !phase.parentPhaseId).map((phase) => `${phase.id!}`),
  );
export const getRecurringPhaseTemplateIds = (requestId: string) =>
  createSelector([getPhases(requestId)], (phases) =>
    phases.filter((phase) => !!phase.isRecurringPhaseTemplate).map((phase) => `${phase.id!}`),
  );

export const getStandardPhaseIdsWithRecurringPhases = (requestId: string) =>
  createSelector([getPhases(requestId), getStandardPhaseIds(requestId)], (phases, standardPhases) =>
    standardPhases.map((standardPhase) => ({
      id: standardPhase,
      recurringPhases: phases.filter((phase) => phase.parentPhaseId === parseInt(standardPhase)).map((p) => `${p.id}`),
    })),
  );

export const getMinDateFrom = (phaseIndex: number, requestId: string) =>
  createSelector([getPhases(requestId), getRequest(requestId), selectSettings], (phases, request, settings) => {
    const proposedDate = latestDateComparedToToday([
      request?.dateFrom,
      phaseIndex > 0
        ? formatDate(
            moment(phases[phaseIndex - 1]?.dateUntil, APP_DEFAULT_DATE_FORMAT)
              .add(1, 'day')
              .subtract(settings?.overlapDays, 'day'),
          )
        : undefined,
    ]);
    return earliestDate([request?.dateUntil, proposedDate]);
  });

export const getInitDateFrom = (phaseIndex: number, requestId: string) =>
  createSelector([getStandardPhases(requestId), getRequest(requestId)], (phases, request) => {
    const proposedDate = latestDateComparedToToday([
      request?.dateFrom,
      phaseIndex > 0
        ? formatDate(moment(phases[phaseIndex - 1]?.dateUntil, APP_DEFAULT_DATE_FORMAT).add(1, 'day'))
        : undefined,
    ]);
    return earliestDate([request?.dateUntil, proposedDate]);
  });

export const getAddresses = (phaseId: string) => createSelector([getPhase(phaseId)], ({ addresses }) => addresses);

export const AllPhasesHaveConstructionZone = (requestId: string) =>
  createSelector([getPhases(requestId)], (phases) => {
    return phases.every((phase) =>
      phase.sgwGeoDrawings.some((drawing) => drawing.geoDrawingType === GeoDrawingType.constructionzone),
    );
  });

export const AllPhasesHaveTrafficMeasures = (requestId: string) =>
  createSelector([getPhases(requestId)], (phases) => {
    return phases.every((phase) => phase.sgwGeoDrawings.some((drawing) => drawing.sgwTrafficMeasures?.length));
  });

export const hasDateOverlap = (phaseIndex: number, requestId: string) =>
  createSelector(
    [
      getStandardPhases(requestId),
      getMinDateFrom(phaseIndex, requestId),
      getMinDateFrom(phaseIndex + 1, requestId),
      getRequest(requestId),
    ],
    (phases, minDateFrom, nextPhaseMinDateFrom, request) => {
      if (!phases.length) {
        return false;
      }

      const curMinDateFrom = moment(minDateFrom, APP_DEFAULT_DATE_FORMAT);
      const curPhaseDateFrom = moment(phases[phaseIndex]?.dateFrom, APP_DEFAULT_DATE_FORMAT);
      const nextMinDateFrom = moment(nextPhaseMinDateFrom, APP_DEFAULT_DATE_FORMAT);
      const nextPhaseDateFrom = phases?.[phaseIndex + 1]?.dateFrom
        ? moment(phases[phaseIndex + 1].dateFrom, APP_DEFAULT_DATE_FORMAT)
        : nextMinDateFrom;

      const currentPhaseHasOverlap = curMinDateFrom > curPhaseDateFrom;
      const nextPhaseHasOverlap = nextMinDateFrom > nextPhaseDateFrom;

      const requestMaxDate = moment(request?.dateUntil, APP_DEFAULT_DATE_FORMAT);
      const curPhaseDateUntil = moment(phases[phaseIndex]?.dateUntil, APP_DEFAULT_DATE_FORMAT);
      const currentPhaseHasOverlapWithRequest = curPhaseDateUntil > requestMaxDate;

      return currentPhaseHasOverlap || nextPhaseHasOverlap || currentPhaseHasOverlapWithRequest;
    },
  );

export const phasesHaveDateOverlap = (requestId: string) =>
  createSelector([(state) => state, getStandardPhaseIds(requestId)], (state, phasesIds) =>
    phasesIds.some((_, index) => hasDateOverlap(index, requestId)(state)),
  );

export const getPhaseById = (requestId: string, phaseId: number) =>
  createSelector([getPhases(requestId)], (phases) => phases.find((phase) => phase.id === phaseId));

export const getCopyPhaseOptions = (requestId: string, phaseId: string) =>
  createSelector(
    [getStandardPhases(requestId), getRecurringPhaseTemplates(requestId)],
    (standardPhases, recurringPhaseTemplates) => [
      ...standardPhases
        ?.filter(({ id, sgwGeoDrawings }) => `${id}` !== phaseId && sgwGeoDrawings.length)
        ?.map((phase) => ({
          value: `${phase.id}`,
          label: translate('sgw.map.drawOnMap.phase.phase', { phase: standardPhases.indexOf(phase) + 1 }),
        })),
      ...recurringPhaseTemplates
        ?.filter(({ id, sgwGeoDrawings }) => `${id}` !== phaseId && sgwGeoDrawings.length)
        ?.map((phase, index) => ({
          value: `${phase.id}`,
          label: translate('sgw.map.drawOnMap.phase.recurringPhaseTemplate', {
            phase: recurringPhaseTemplates.indexOf(phase) + 1,
          }),
        })),
    ],
  );
