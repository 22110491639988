import { FormProvider } from 'react-hook-form';
import React, { FC, FormEvent, useCallback } from 'react';
import { PhaseGeneralFields } from '../../../organisms';
import { usePhaseForm } from '../../../../hooks';
import { IconButton } from '../../../atoms';
import { PhaseAccordion } from '../../../molecules';
import { submitPhaseFormId } from '../../../../common/utils/formUtils';
import { translate } from '../../../../translations/translate';
import { NatureOfWorkFields } from '../../../organisms/NatureOfWorksFields/NatureOfWorkFields.component';
import { TrafficMeasureFields } from '../../../organisms/TrafficMeasureFields/TrafficMeasureFields';
import { PhaseAddressFields } from '../../../organisms/PhaseAddressFields/PhaseAddressFields.component';
import { useSelector } from 'react-redux';
import { getRequestDateUntil } from '../../../../store/selectors/sgw';
import { useParams } from 'react-router';
import { TRouterWithId } from '../../../../types';

interface IProps {
  id: string;
  index: number;
  copyId?: string;
  recurringPhaseTemplate?: boolean;
  onCreate?(): void;
  onCopy?(id: string): void;
  onDelete?(id: string, index: number): void;
}

export const PhaseForm: FC<IProps> = ({ id, index, copyId, recurringPhaseTemplate, onCreate, onCopy, onDelete }) => {
  const { formMethods, submitPhase, minDateFrom, hasDateOverlap } = usePhaseForm(id, index, copyId);
  const {
    formState: { errors },
    watch,
  } = formMethods;
  const { id: requestId } = useParams<keyof TRouterWithId>();
  const maxDateUntil = useSelector(getRequestDateUntil(requestId));
  const phase = watch();

  const _onSubmit = useCallback(
    (e: FormEvent) => {
      e?.preventDefault();
      if (!Object.keys(errors).length) {
        onCreate?.();
        return submitPhase(false, recurringPhaseTemplate)(e);
      }
    },
    [errors, onCreate, recurringPhaseTemplate, submitPhase],
  );

  return (
    <div data-testid={'CapacityAndPartnerForm'}>
      <FormProvider {...formMethods}>
        <form id={submitPhaseFormId(index, recurringPhaseTemplate)} onSubmit={_onSubmit}>
          <PhaseAccordion
            key={id}
            index={index}
            onCopy={onCopy}
            onDelete={onDelete}
            showError={hasDateOverlap}
            {...phase}
            id={id}
            recurringPhaseTemplate={recurringPhaseTemplate}
          >
            <PhaseGeneralFields
              id={id}
              minDateFrom={minDateFrom}
              maxDateUntil={maxDateUntil}
              recurringPhaseTemplate={recurringPhaseTemplate}
              index={index}
            />
            <PhaseAddressFields />
            <NatureOfWorkFields id={id} />
            <TrafficMeasureFields id={id} />
            <IconButton icon="save" form={submitPhaseFormId(index, recurringPhaseTemplate)}>
              {translate('sgw.phases.save')}
            </IconButton>
          </PhaseAccordion>
        </form>
      </FormProvider>
    </div>
  );
};
