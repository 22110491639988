import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import * as A from 'antwerp-core-react-branding';
import { FormProvider, useWatch } from 'react-hook-form';
import { submitId } from '../../../../common/utils/formUtils';
import { GeoDrawingType, SgwWorkflowStep, WorkflowType } from '../../../../types';
import { useGenericStyles, usePhaseForm, useWorkflowHistory } from '../../../../hooks';
import { MapActionHeader, MapActionButtons, GeoDrawingList } from '../../../molecules';
import { Fieldset } from '../../../atoms';
import { translate } from '../../../../translations/translate';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';
import { COLORS } from '../../../../theme';
import { GeoDrawingContextProvider } from '../../../../context';
import { GeoDrawingMap } from '../../../organisms/GeoDrawingMap/GeoDrawingMap.component';
import { useDispatch, useSelector } from 'react-redux';
import { createErrorAlertAction } from '../../../../common/actions/creators/alert';
import {
  AllPhasesHaveConstructionZone,
  AllPhasesHaveTrafficMeasures,
  getPhaseIds,
} from '../../../../store/selectors/sgw';
import { Visible } from '../../../common/layout/Visible.component';
import { atomWithHash } from 'jotai/utils';
import { useAtom } from 'jotai';
import { MapTrafficMeasures } from '../../../organisms/MapTrafficMeasures/MapTrafficMeasures.component';

interface IProps {
  requestId: string;
  readOnly?: boolean;
}

const useStyles = createUseStyles({
  baseMapContainer: {
    '& > div': {
      height: '600px!important',
    },
  },
  mapActionContainer: {
    border: `1px solid ${COLORS.lightSilver}`,
    height: 'fit-content',
  },
  fieldSet: {
    border: 'none',
    padding: '0px',
  },
});

export const phaseIdAtom = atomWithHash<string | number>('phaseId', '', { replaceState: true });

export const GeoDrawingsForm: FC<IProps> = ({ requestId, readOnly = false }) => {
  const step = SgwWorkflowStep.Map;
  const C = useStyles();
  const dispatch = useDispatch();
  const { spacing, flex } = useGenericStyles();
  const [phaseId, setPhaseId] = useAtom(phaseIdAtom);
  const { formMethods, submitPhase } = usePhaseForm(`${phaseId}`, 0);
  const { goToNextPage } = useWorkflowHistory();
  const { setValue, control, getValues } = formMethods;
  const geoDrawings = useWatch({ control, name: 'sgwGeoDrawings' }) || getValues('sgwGeoDrawings');
  const allHaveConstructionZones = useSelector(AllPhasesHaveConstructionZone(requestId));
  const allHaveTrafficMeasures = useSelector(AllPhasesHaveTrafficMeasures(requestId));
  const phaseIds = useSelector(getPhaseIds(requestId));
  const [trafficMeasureError, setTrafficMeasureError] = useState(false);

  useEffect(() => {
    if (!phaseIds.some((id) => `${id}` === phaseId)) {
      setPhaseId(phaseIds[0]);
    }
    if (
      geoDrawings?.some(
        ({ geoDrawingType, sgwTrafficMeasures }) =>
          geoDrawingType === GeoDrawingType.constructionzone && !sgwTrafficMeasures?.length,
      )
    ) {
      setTrafficMeasureError(true);
    } else {
      setTrafficMeasureError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geoDrawings, phaseIds.length, phaseId, setPhaseId]);

  const onSavePhase =
    (hideSnackBar: boolean = false, recurringPhase: boolean = false) =>
    (e: SyntheticEvent) => {
      e.preventDefault();
      setValue('sgwGeoDrawings', geoDrawings);
      submitPhase(hideSnackBar, recurringPhase)(e);
    };

  const onGoToNextPage = (e: SyntheticEvent) => {
    e.preventDefault();

    if (!allHaveConstructionZones || !allHaveTrafficMeasures) {
      dispatch(
        createErrorAlertAction(
          translate('sgw.map.drawOnMap.phase.constructionZoneMandatoryForAllPhases'),
          translate('sgw.map.drawOnMap.phase.savePhase'),
        ),
      );
    }
    allHaveConstructionZones && !trafficMeasureError && allHaveTrafficMeasures && goToNextPage(step);
  };

  return (
    <div data-testid={'GeoDrawingsForm'} className="leaflet__container__wrapper">
      <FormProvider {...formMethods}>
        <GeoDrawingContextProvider
          requestId={requestId}
          geoDrawings={geoDrawings}
          savePhase={onSavePhase}
          phaseId={`${phaseId}`}
        >
          <form id={submitId(WorkflowType.Sgw, step)} onSubmit={onGoToNextPage}>
            <Fieldset
              legend={readOnly ? '' : translate('sgw.map.drawOnMap.legend')}
              data-testid={'drawOnMapFieldset'}
              className={readOnly ? C.fieldSet : undefined}
            >
              <Visible visible={!readOnly}>
                {/*@ts-ignore*/}
                <A.Paragraph spacing>{translate('sgw.map.drawOnMap.info')}</A.Paragraph>
              </Visible>
              <div
                className={classNames('row', flex.row, readOnly ? null : spacing.marginTop.LG, spacing.marginRight.XXS)}
              >
                <div className={classNames(flex.column, C.mapActionContainer, 'col-md-3', 'col-xs-12')}>
                  <MapActionHeader disableSave={trafficMeasureError} readOnly={readOnly} />
                  <GeoDrawingList readOnly={readOnly} />
                  <Visible visible={!readOnly}>
                    <MapActionButtons phaseId={`${phaseId}`} />
                  </Visible>
                </div>
                <div className={classNames('leaflet__viewmap', flex.column, 'col-md-9', 'col-xs-12')}>
                  <GeoDrawingMap readOnly={readOnly} />
                </div>
              </div>
            </Fieldset>
            <Visible visible={!!geoDrawings?.length && !readOnly}>
              <MapTrafficMeasures formMethods={formMethods} trafficMeasureError={trafficMeasureError} />
            </Visible>
          </form>
        </GeoDrawingContextProvider>
      </FormProvider>
    </div>
  );
};
