import * as React from 'react';
import { FC, SyntheticEvent } from 'react';
import { IOption } from '../../molecules';
import { ControllerFieldState, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import { createUseStyles } from 'react-jss';

interface IProps {
  'data-testid'?: string;
  options: IOption[];
  fieldState?: ControllerFieldState;
  label: string;
  name: string;
  onSelect: (e: SyntheticEvent<HTMLSelectElement>) => void;
  emptyOption?: boolean;
  disabled?: boolean;
}

const useStyles = createUseStyles({
  root: {
    marginBottom: '9px',
    marginTop: '9px',
    display: 'flex',
    cursor: 'pointer',
  },
});

export const Select: FC<IProps> = ({
  label,
  onSelect,
  options,
  name,
  emptyOption = true,
  disabled = false,
  ...props
}) => {
  const C = useStyles();
  const { getValues } = useFormContext();
  const isSelected = (value: string) => `${getValues(name)}` === value;

  return (
    <div className={classNames('a-input', 'has-icon-right', C.root)} {...props}>
      <div className="a-input__wrapper">
        <select
          aria-label={label}
          placeholder={label}
          onChange={(event: SyntheticEvent<HTMLSelectElement>) => onSelect(event)}
          onKeyDown={(event) => {
            // AS-6736 prevent the form from submitting on pressing Enter
            event.key === 'Enter' && event.preventDefault();
          }}
          disabled={disabled}
        >
          {emptyOption ? <option value={undefined}>{label}</option> : null}
          {options.map(({ label, value, disabled }) => (
            <option value={value} key={value} selected={isSelected(value)} disabled={disabled}>
              {label}
            </option>
          ))}
        </select>
        <i className={`fa fa-angle-down`} />
      </div>
    </div>
  );
};
