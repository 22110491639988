import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Snackbar } from '../../atoms/Snackbar/Snackbar.component';
import { SnackBarActions } from '../../../store/actions/Snackbar.actions';
import { selectSnackbarFeedback } from '../../../store/selectors';

export const GenericSnackBar: FC = () => {
  const dispatch = useDispatch();
  const snackBar = useSelector(selectSnackbarFeedback);

  const onClose = () => {
    dispatch(SnackBarActions.setFeedback(null));
  };

  return (
    <Snackbar show={!!snackBar} onClose={onClose} variant={snackBar?.variant} duration={snackBar?.duration}>
      {snackBar?.feedback}
    </Snackbar>
  );
};
