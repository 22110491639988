import intl from 'react-intl-universal';
import { createInfoAlertAction } from '../common/actions/creators/alert';
import { getVatInfo } from '../common/api';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ValidateResult } from 'react-hook-form/dist/types/validator';

export const useVATValidationGenerator: (
  shouldValidate: boolean,
  vatNumberField: string,
  companyNameField: string,
) => (vatValue: string) => Promise<ValidateResult> = (shouldValidate, vatNumberField, companyNameField) => {
  const { setError, setValue, trigger } = useFormContext();
  const companyName = useWatch({ name: companyNameField });
  const dispatch = useDispatch();

  return async (vatValue: string) => {
    if (!shouldValidate) return true;
    try {
      const response = await getVatInfo(vatValue || '');
      if (!response.valid) {
        setError(vatNumberField, {
          type: 'manual',
          message: intl.get(`fields.NoValidVat.${response.issuedBy || 'general'}`),
        });
        return intl.get(`fields.NoValidVat.${response.issuedBy || 'general'}`);
      }

      if (response.vatNumber) {
        setValue(vatNumberField, response.vatNumber);
      }

      if (response.name) {
        setValue(companyNameField, response.name);
        await trigger(companyNameField);
      } else if (!companyName) {
        dispatch(createInfoAlertAction(intl.get('personalform.vatvalidbutnotfound', { vat: vatValue })));
        setError(companyNameField, {
          type: 'manual',
          message: intl.get('fields.required'),
        });
      }
    } catch (e) {
      return intl.get('fields.NoValidVat.general');
    }

    return true;
  };
};
