import './header.scss';
import React, { FC, useCallback } from 'react';
import * as A from 'antwerp-core-react-branding';
import intl from 'react-intl-universal';
import { APP_CORPORATE_MAIN_SITE } from '../../../common/constants';
import { Toggle } from './Toggle.component';
import { useDispatch, useSelector } from 'react-redux';
import { getProfileName } from '../../../store/selectors';
import { ISelect } from '../../../types';
import { ReactComponent as Logo } from './a-logo.svg';
import { useIsMaxZoomLevel } from '../../../hooks';
import { ApplicationActions } from '../../../store/actions/workflow.actions';

export const Header: FC = () => {
  const dispatch = useDispatch();
  const profileName: ISelect<typeof getProfileName> = useSelector(getProfileName);

  const logout = useCallback(() => {
    dispatch(ApplicationActions.logout());
  }, [dispatch]);

  return (
    <header className={`row o-header o-header--fixed aui-header ${useIsMaxZoomLevel() ? ' zoomed-in' : ''}`}>
      <a className="o-header__logo" href={APP_CORPORATE_MAIN_SITE} target="_blank" rel="noreferrer">
        <Logo title="Rechtstreekse link naar website Stad Antwerpen" />
      </a>
      <div className="aui-header__content-wrapper">
        <div className="aui-header__content">
          <div className="aui-header__menu-items">
            {/*@ts-ignore*/}
            <A.IconButton
              location={A.Locations.Left}
              icon="sign-out"
              onClick={logout}
              text={profileName || intl.get('general.logout')}
            />
            <Toggle />
          </div>
        </div>
      </div>
    </header>
  );
};
