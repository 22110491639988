import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { submitId } from '../../../../common/utils/formUtils';
import { SgwWorkflowStep, WorkflowType } from '../../../../types';
import { useSgwRequestForm } from '../../../../hooks';
import { MainLocationFields } from '../../../organisms/Phases';
import { InvalidSgwRequestStartDateAlert, PhaseOverview } from '../../../organisms';
import { useSelector } from 'react-redux';
import { phasesHaveDateOverlap, getPhaseIds, getStandardPhases } from '../../../../store/selectors/sgw';
import { validateRequestPhases } from '../../../../common/utils/sgwRequestUtils';
import { Alert } from '../../../molecules';
import { translate } from '../../../../translations/translate';

export const PhasesForm: FC = () => {
  const step = SgwWorkflowStep.Phases;
  const { formMethods, submitRequest, requestId, request } = useSgwRequestForm(step);
  const { setValue, register } = formMethods;
  const phaseIds = useSelector(getPhaseIds(requestId!));
  const phases = useSelector(getStandardPhases(requestId!));
  const haveDateOverlap = useSelector(phasesHaveDateOverlap(requestId!));
  const [dateInfoAlert, setDateInfoAlert] = useState(false);

  useEffect(() => {
    setValue('phases', phaseIds);
  }, [setValue, phaseIds]);

  const _onSubmitRequest = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!haveDateOverlap) {
      phases[phases.length - 1].dateUntil === request?.dateUntil ? submitRequest(e) : setDateInfoAlert(true);
    }
  };

  const syncRequestStartDate = () => {
    setDateInfoAlert(false);
    if (!haveDateOverlap) {
      formMethods.setValue('dateUntil', phases[phases.length - 1].dateUntil);
      submitRequest();
    }
  };

  return (
    <div data-testid={'PhasesForm'}>
      <FormProvider {...formMethods}>
        <InvalidSgwRequestStartDateAlert />
        <Alert
          title={translate('sgw.phases.phaseDateInfoAlert.title')}
          message={translate('sgw.phases.phaseDateInfoAlert.description')}
          discardButtonText={translate('general.cancel')}
          onClose={() => setDateInfoAlert(false)}
          onConfirm={syncRequestStartDate}
          visible={dateInfoAlert}
        />
        <form id={submitId(WorkflowType.Sgw, step)} onSubmit={_onSubmitRequest}>
          <MainLocationFields />
        </form>
        <PhaseOverview
          requestId={requestId!}
          {...register('phases', {
            required: true,
            validate: () => validateRequestPhases(phases),
          })}
        />
      </FormProvider>
    </div>
  );
};
