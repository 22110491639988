import { IDefaultAction, IGenericAction } from '../../../store/actions/actions';
import { defaultAction, genericAction } from '../../../store/actions/creators/baseCreators';
import { IAlert } from '../../../types';
import { Levels } from '../../enums';
import { ALERT, ALERT_RESET, CONFIRM, CONFIRM_CANCEL, CONFIRM_OK, CONFIRM_RESET } from '../types';

export function createAlertAction(
  message: string,
  level: Levels,
  title?: string,
  onOk?: () => void,
): IGenericAction<Partial<IAlert>> {
  return genericAction(ALERT, {
    level,
    message,
    title,
    onOk,
  });
}

export function createInfoAlertAction(message: string, title?: string) {
  return createAlertAction(message, Levels.Primary, title);
}

export function createWarningAlertAction(message: string, title?: string) {
  return createAlertAction(message, Levels.Warning, title);
}

export function createErrorAlertAction(message: string, title?: string, onOk?: () => void) {
  return createAlertAction(message, Levels.Danger, title, onOk);
}

export function createAlertResetAction(): IDefaultAction {
  return defaultAction(ALERT_RESET);
}

export function createConfirmAction(
  message: string,
  title?: string,
  onOk?: () => void,
  onCancel?: () => void,
): IGenericAction<Partial<IAlert>> {
  return genericAction(CONFIRM, {
    level: Levels.Primary,
    message,
    title,
    onOk,
    onCancel,
  });
}

export function createConfirmResetAction(): IDefaultAction {
  return defaultAction(CONFIRM_RESET);
}

export function createConfirmOKAction(): IDefaultAction {
  return defaultAction(CONFIRM_OK);
}

export function createConfirmCancelAction(): IDefaultAction {
  return defaultAction(CONFIRM_CANCEL);
}
