import * as A from 'antwerp-core-react-branding';
import moment from 'moment/moment';
import { ValidateResult } from 'react-hook-form/dist/types/validator';
import intl from 'react-intl-universal';
import { translate } from '../../translations/translate';
import { IRequest } from '../../types';
import debug from '../debug';
import { convertStringToTimestamp, formatDateToDisplayDate, formatToDisplayDate } from '../utils/dateUtils';
import { isValid } from '../utils/formUtils';
import { parseHour, parseMinutes } from '../utils/hourUtils';

export function validateTime(value: string, entireDay?: boolean): ValidateResult {
  if (!entireDay && !value) {
    return intl.get('fields.novalidtime');
  }
  return true;
}

export function validateDate(
  label: string,
  value: string,
  minDate?: string,
  maxDate?: string,
  disableCheckInThePast?: boolean,
): ValidateResult {
  const format = /[0-9]{4}-[0-9]{2}-[0-9]{2}/i;

  if (!value) {
    return translate('fields.labelRequired', { label });
  }

  if (!format.test(value)) {
    return translate('fields.noValidDate');
  }

  const timestamp = convertStringToTimestamp(value);
  if (!disableCheckInThePast && timestamp < new Date().getTime()) {
    return translate('fields.dateInThePast');
  }
  if (minDate) {
    const minTimestamp = convertStringToTimestamp(minDate);
    if (timestamp < minTimestamp) {
      return translate('fields.dateLowerThanMinDate', { minDate: formatToDisplayDate(minDate, -1) });
    }
  }

  if (maxDate) {
    const maxTimestamp = convertStringToTimestamp(maxDate);
    if (timestamp > maxTimestamp) {
      const maxDateToDisplay = moment(maxTimestamp).add(1, 'day');
      return translate('fields.dateHigherThanMaxDate', { maxDate: formatDateToDisplayDate(maxDateToDisplay) });
    }
  }

  return true;
}

export function validateTimeInterval(timeFrom: string, timeUntil: string, entireDay?: boolean): ValidateResult {
  const validateTimeMessage = validateTime(timeUntil, entireDay);
  if (!isValid(validateTimeMessage)) {
    return validateTimeMessage;
  }

  if (timeFrom) {
    const fromHours = parseHour(timeFrom);
    const untilHours = parseHour(timeUntil);
    const fromMinutes = parseMinutes(timeFrom);
    const untilMinutes = parseMinutes(timeUntil);

    if (untilHours * 60 + untilMinutes - (fromHours * 60 + fromMinutes) <= 0) {
      return intl.get('fields.timeinterval');
    }
  }

  return true;
}

export function validateDateInterval(label: string, value: string, allValues: Partial<IRequest>): ValidateResult {
  if (!value) {
    return intl.get('fields.labelRequired', { label: intl.get('fields.endDate') });
  }
  if (allValues.dateFrom) {
    if (isValid(validateDate(label, allValues.dateFrom)) && isValid(validateDate(label, value))) {
      try {
        const from = moment(allValues.dateFrom, A.DatePickerInput.DefaultDateFormat).toDate().getTime();
        const until = moment(value, A.DatePickerInput.DefaultDateFormat).toDate().getTime();
        if (from > until) {
          return intl.get('fields.novaliddatemustbegreater');
        }
      } catch (e: any) {
        debug(`Cannot check dateinterval: ${e.message}`, { from: value, until: allValues.dateUntil });
      }
    } else {
      return validateDate(label, value);
    }
  }

  return true;
}

export function validateDateTimeInterval(
  dateFrom: string,
  dateUntil: string,
  timeFrom: string,
  timeUntil: string,
  entireDay?: boolean,
): ValidateResult {
  if (timeFrom && dateFrom === dateUntil) {
    return validateTimeInterval(timeFrom, timeUntil, entireDay);
  }

  return true;
}
