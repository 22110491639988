import { ValidateResult } from 'react-hook-form/dist/types/validator';
import { geoCodeAddress } from '../api';
import { TENANT_NAME } from '../constants';
import { translate } from '../../translations/translate';

export const validateAddress = async (
  street?: string,
  streetNumber?: string,
  errorMessage?: string,
): Promise<ValidateResult> => {
  if (!street) {
    return true;
  }

  const response = await geoCodeAddress({ street, streetNumber: streetNumber, city: TENANT_NAME });
  if (!response.data) {
    return errorMessage || translate('locationsform.invalidAddress');
  }

  return true;
};
